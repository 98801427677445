export function trackPredictions(prediction, movingAveragePredictionMap) {
  //   console.log("trackPredictions", { prediction, movingAveragePredictionMap });
  const predToIncrement = movingAveragePredictionMap.get(prediction.className);
  if (movingAveragePredictionMap !== null) {
    if (!predToIncrement) {
      movingAveragePredictionMap.set(prediction.className, 1);
    } else {
      movingAveragePredictionMap.set(prediction.className, predToIncrement + 1);
    }
    return movingAveragePredictionMap;
  } else {
    let predictionMap = new Map();

    predictionMap.set(prediction.className, 1);
    return predictionMap;
  }
}
