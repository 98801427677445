export const commands = [
  // "right_ear",
  // "left_ear",
  "raise_right_hand",
  "raise_left_hand",
  "touch_nose",
  "right_hand_chest",
  "left_hand_chest",
  // "neutral",
];

export const friendlyCommand = (command) => {
  return COMMAND_MAP().get(command);
};

export const COMMAND_MAP = () => {
  const map = new Map();
  map.set("right_ear", "Touch your right ear");
  map.set("left_ear", "Touch your left ear");
  map.set("raise_right_hand", "Raise your right hand");
  map.set("raise_left_hand", "Raise your left hand");
  map.set("touch_nose", "Touch your nose");
  map.set("right_hand_chest", "Put your right hand on your chest");
  map.set("left_hand_chest", "Put your left hand on your chest");
  return map;
};
// const rawModelCommands = require("../models/metadata.json").labels;

// export const commands = rawModelCommands.splice(
//   rawModelCommands.indexOf("neutral"),
//   1
// );

export const nextRandomCommand = () => {
  const responseCommand = commands[Math.floor(Math.random() * commands.length)];
  // const responseMapCommand = COMMAND_MAP.get(responseCommand);
  // console.log("nextRandomCommand: ", { responseCommand, responseMapCommand });
  return responseCommand;
};
