import logo from "./logo.svg";
import RandomRoller from "react-random-roller";

import "./App.css";
import * as tmPose from "@teachablemachine/pose";
import { useEffect, useState } from "react";
import {
  commands,
  COMMAND_MAP,
  friendlyCommand,
  nextRandomCommand,
} from "./constants/Commands";
import Marvelous from "./Marvelous.png";
import { trackPredictions } from "./util.js/Util";
import { analytics } from "./index";
import { logEvent } from "firebase/analytics";
import useWindowDimensions from "./hooks/useWindowDimensions";

function App() {
  const URL = "{{URL}}";
  let model,
    webcam,
    ctx,
    labelContainer,
    maxPredictions,
    predictionContainer,
    commandHeader,
    fpsContainer;
  const times = [];
  let fps;
  let predictionTracker = new Map();
  const CLASS_COUNT_THRESHOLD = 15;
  let command = commands[1];
  let friendlyCommandVar = friendlyCommand(commands[1]);

  function setCommand(commandParm) {
    console.log("commandParm", commandParm);
    command = commandParm;
    friendlyCommandVar = friendlyCommand(commandParm);
    commandHeader.innerHTML = friendlyCommandVar;
  }
  const [reaction, setReaction] = useState("");
  const [debug, setDebug] = useState(process.env.REACT_APP_DEBUG);
  const { height, width } = useWindowDimensions();
  function screenOrientationAndSizing(height, width) {
    let adjustedSize, orientation;
    if (height > width) {
      //vertical
      orientation = "vertical";
      adjustedSize = width - 50;
    } else {
      //horizontal
      orientation = "horizontal";
      adjustedSize = height * 0.6;
    }
    logEvent(analytics, "screenOrientationAndSizing", {
      height,
      width,
      orientation,
      adjustedSize,
    });
    return [orientation, adjustedSize];
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    console.log("Window Dims:", { height, width });
    console.log(process.env);
  }, [height, width]);
  return (
    <div className="App">
      <header className="App-header">
        <h1>Simon Says...</h1>
        <h2 id="commandHeader">{friendlyCommandVar}</h2>
        {debug === "true" ? (
          <>
            <button
              onClick={() => {
                setCommand(nextRandomCommand());
                logEvent(analytics, "next_command_button");
              }}
            >
              Next
            </button>
            <p id="fps-container">Fps:{fps}</p>
          </>
        ) : (
          <div></div>
        )}
        <div>
          <canvas id="canvas"></canvas>
        </div>
        <h1 id="prediction"></h1>
        <div id="label-container"></div>
      </header>
      <h1 id="prediction"></h1>
      <footer className="App-footer">
        <button
          onClick={() => {
            logEvent(analytics, "toggle_debug_mode");
            setDebug(!debug);
          }}
        >
          debug toggle
        </button>
      </footer>
    </div>
  );
  async function init() {
    logEvent(analytics, "model_init");
    const modelURL = "./model/model.json";
    const metadataURL = "./model/metadata.json";
    const [orientation, adjustedSize] = screenOrientationAndSizing(
      height,
      width
    );
    model = await tmPose.load(modelURL, metadataURL);
    maxPredictions = model.getTotalClasses();

    const flip = true;
    webcam = new tmPose.Webcam(adjustedSize, adjustedSize, flip); // width, height, flip
    await webcam.setup();
    webcam.play();
    window.requestAnimationFrame(loop);

    const canvas = document.getElementById("canvas");
    canvas.width = adjustedSize;
    canvas.height = adjustedSize;
    ctx = canvas.getContext("2d");
    labelContainer = document.getElementById("label-container");
    predictionContainer = document.getElementById("prediction");
    commandHeader = document.getElementById("commandHeader");
    fpsContainer = document.getElementById("fps-container");
    predictionContainer.appendChild(document.createElement("div"));

    for (let i = 0; i < maxPredictions; i++) {
      // and class labels
      labelContainer.appendChild(document.createElement("div"));
    }
  }

  async function loop(timestamp) {
    webcam.update();
    await predict();
    window.requestAnimationFrame(loop);
    refreshLoop();
  }

  function refreshLoop() {
    window.requestAnimationFrame(() => {
      const now = performance.now();
      while (times.length > 0 && times[0] <= now - 1000) {
        times.shift();
      }
      times.push(now);
      fps = times.length;
      if (debug === "true") {
        fpsContainer.innerHTML = "FPS: " + fps;
      }
    });
  }
  async function predict() {
    // Prediction #1: run input through posenet
    // estimatePose can take in an image, video or canvas html element
    const { pose, posenetOutput } = await model.estimatePose(webcam.canvas);

    // Prediction 2: run input through teachable machine classification model
    const prediction = await model.predict(posenetOutput);

    const max = prediction.reduce(function (prev, current) {
      return prev.probability > current.probability ? prev : current;
    });
    // console.log("prediction", max);
    // prediction = prediction.sort((a, b) => a.probability - b.probability);

    // TODO: sort these
    for (let i = 0; i < maxPredictions; i++) {
      const classPrediction =
        prediction[i].className + ": " + prediction[i].probability.toFixed(2);
      if (debug === "true") {
        labelContainer.childNodes[i].innerHTML = classPrediction;
      }
    }
    predictionContainer.childNodes[0].innerHTML =
      max.className + ": " + max.probability.toFixed(2);

    const tracker = trackPredictions(max, predictionTracker);

    // console.log({
    //   commandz: command,
    //   class: max.className,
    //   prob: max.probability,
    // });
    if (max.className === command) {
      console.log("command: ", command);
      console.log("NICE MOVE: ", tracker);
      const classCount = predictionTracker.get(max.className);
      if (classCount >= CLASS_COUNT_THRESHOLD) {
        console.log("prediction === command!");
        // alert("GREAT JOB!!!");
        setCommand(nextRandomCommand());
        predictionTracker = new Map();
        setReaction("good");
      }
    }
    // finally draw the poses
    drawPose(pose);
  }

  function drawPose(pose) {
    ctx.drawImage(webcam.canvas, 0, 0);
    // draw the keypoints and skeleton
    if (pose) {
      const minPartConfidence = 0.5;
      tmPose.drawKeypoints(pose.keypoints, minPartConfidence, ctx);
      tmPose.drawSkeleton(pose.keypoints, minPartConfidence, ctx);
    }
  }
}

// function pickItem() {
//   console.log("picking item");
//   const list = commands;

//   let startTime;

//   let fps = 10;
//   let now;
//   let then = Date.now();
//   let interval = 1000 / fps;
//   let delta;

//   const spin = (timestamp, duration) => {
//     timestamp = timestamp || new Date().getTime();

//     let runTime = timestamp - startTime;
//     let progress = runTime / duration;

//     progress = Math.min(progress, 1);

//     // check if run time is met
//     if (runTime < duration) {
//       requestAnimationFrame((timestamp) => {
//         spin(timestamp, duration);
//       });
//     }

//     now = Date.now();
//     delta = now - then;

//     if (delta > interval) {
//       then = now - (delta % interval);

//       App.setCommand(list[Math.floor(Math.random() * list.length)]);
//     }
//   };

//   requestAnimationFrame((timestamp) => {
//     startTime = timestamp || new Date().getTime();
//     spin(startTime, 2000);
//   });
// }

// const commands = ["Touch your Right Ear", "Touch your Left Ear"];

export default App;
