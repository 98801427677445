import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAdVVb9qFSpkumiNoL6PWoiMAdplHAOZao",
  authDomain: "nah5-265801.firebaseapp.com",
  databaseURL: "https://nah5-265801.firebaseio.com",
  projectId: "nah5-265801",
  storageBucket: "nah5-265801.appspot.com",
  messagingSenderId: "710837493316",
  appId: "1:710837493316:web:32d5c9d58f9a096a68a611",
  measurementId: "G-WH7BJM30CE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
